import React from 'react';
import { Calendar, AlertTriangle, FileText, BookOpen } from 'lucide-react';

interface StandActionsProps {
  isReserved: boolean;
  onReserve: () => void;
  onPosterRequest: () => void;
  onMaintenanceRequest: () => void;
  onStockAlert: () => void;
  onCancelReservation: () => void;
}

const StandActions: React.FC<StandActionsProps> = ({
  isReserved,
  onReserve,
  onPosterRequest,
  onMaintenanceRequest,
  onStockAlert,
  onCancelReservation
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
      {isReserved ? (
        <button
          onClick={onCancelReservation}
          className="btn bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/50"
        >
          <Calendar className="h-4 w-4 mr-2" />
          Annuler la réservation
        </button>
      ) : (
        <button
          onClick={onReserve}
          className="btn bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700 shadow-lg hover:shadow-xl"
        >
          <Calendar className="h-4 w-4 mr-2" />
          Réserver ce présentoir
        </button>
      )}
      
      <button
        onClick={onPosterRequest}
        className="btn bg-gradient-to-r from-purple-500 to-purple-600 text-white hover:from-purple-600 hover:to-purple-700 shadow-lg hover:shadow-xl"
      >
        <FileText className="h-4 w-4 mr-2" />
        Demander un changement d'affiche
      </button>

      <button
        onClick={onMaintenanceRequest}
        className="btn bg-gradient-to-r from-yellow-500 to-yellow-600 text-white hover:from-yellow-600 hover:to-yellow-700 shadow-lg hover:shadow-xl"
      >
        <AlertTriangle className="h-4 w-4 mr-2" />
        Signaler un problème
      </button>

      <button
        onClick={onStockAlert}
        className="btn bg-gradient-to-r from-orange-500 to-orange-600 text-white hover:from-orange-600 hover:to-orange-700 shadow-lg hover:shadow-xl"
      >
        <BookOpen className="h-4 w-4 mr-2" />
        Signaler un stock bas
      </button>
    </div>
  );
};

export default StandActions;
export const POSTER_CATEGORIES = [
  'Périodiques',
  'Brochures',
  'Campagnes',
  'Invitations'
] as const;

export const PUBLICATION_CATEGORIES = [
  'Livres',
  'Carte de Visites',
  'Brochures',
  'Tour De Garde',
  'Réveillez-vous'
] as const;
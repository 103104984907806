import React, { useState } from 'react';
import { useOrganization } from '../../context/OrganizationContext';
import { Shield, Key, RefreshCw, Copy, Clock } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { refreshAccessCode } from '../../lib/security';
import { differenceInHours, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

const SecuritySettings: React.FC = () => {
  const { currentOrganization, setCurrentOrganization } = useOrganization();
  const [loading, setLoading] = useState(false);

  const handleRefreshCode = async () => {
    if (!currentOrganization?.id) return;

    try {
      setLoading(true);
      const newCode = await refreshAccessCode(currentOrganization.id);
      const expiryDate = Timestamp.fromDate(new Date(Date.now() + 12 * 60 * 60 * 1000));

      await updateDoc(doc(db, 'organizations', currentOrganization.id), {
        accessCode: newCode,
        accessCodeExpiry: expiryDate
      });

      setCurrentOrganization({
        ...currentOrganization,
        accessCode: newCode,
        accessCodeExpiry: expiryDate
      });

      toast.success('Code d\'accès renouvelé avec succès');
    } catch (error) {
      console.error('Error refreshing access code:', error);
      toast.error('Erreur lors du renouvellement du code');
    } finally {
      setLoading(false);
    }
  };

  const copyAccessCode = () => {
    if (!currentOrganization?.accessCode) return;
    
    navigator.clipboard.writeText(currentOrganization.accessCode);
    toast.success('Code d\'accès copié');
  };

  const getExpiryStatus = () => {
    if (!currentOrganization?.accessCodeExpiry) return null;

    try {
      // Handle both Timestamp and Date objects
      const expiryDate = 'toDate' in currentOrganization.accessCodeExpiry 
        ? currentOrganization.accessCodeExpiry.toDate()
        : new Date(currentOrganization.accessCodeExpiry);

      if (!(expiryDate instanceof Date) || isNaN(expiryDate.getTime())) {
        return null;
      }

      const hoursLeft = differenceInHours(expiryDate, new Date());

      if (hoursLeft <= 0) {
        return {
          text: 'Expiré',
          color: 'text-red-600 bg-red-100'
        };
      } else if (hoursLeft <= 2) {
        return {
          text: `Expire dans ${formatDistanceToNow(expiryDate, { locale: fr })}`,
          color: 'text-orange-600 bg-orange-100'
        };
      } else {
        return {
          text: `Expire dans ${formatDistanceToNow(expiryDate, { locale: fr })}`,
          color: 'text-green-600 bg-green-100'
        };
      }
    } catch (error) {
      console.error('Error calculating expiry status:', error);
      return null;
    }
  };

  const expiryStatus = getExpiryStatus();

  return (
    <div className="space-y-8">
      <div className="card p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-blue-100 text-blue-600 rounded-lg">
            <Shield className="h-5 w-5" />
          </div>
          <div>
            <h2 className="text-xl font-bold text-gray-900">
              Paramètres de Sécurité
            </h2>
            <p className="text-gray-600 text-sm">
              Gérez les paramètres de sécurité de votre assemblée
            </p>
          </div>
        </div>

        <div className="space-y-6">
          {/* Code d'accès */}
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
            <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
              <Key className="h-5 w-5 text-gray-600" />
              Code d'accès
            </h3>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="bg-white px-4 py-2 rounded-lg border border-gray-200 font-mono text-lg">
                    {currentOrganization?.accessCode || 'XXXXXXXX'}
                  </div>
                  <button
                    onClick={copyAccessCode}
                    className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
                    title="Copier le code"
                  >
                    <Copy className="h-5 w-5" />
                  </button>
                </div>

                <button
                  onClick={handleRefreshCode}
                  disabled={loading}
                  className="btn btn-secondary"
                >
                  <RefreshCw className={`h-4 w-4 mr-2 ${loading ? 'animate-spin' : ''}`} />
                  Renouveler
                </button>
              </div>

              {expiryStatus && (
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4 text-gray-500" />
                  <span className={`px-2 py-1 rounded-full text-sm ${expiryStatus.color}`}>
                    {expiryStatus.text}
                  </span>
                </div>
              )}

              <p className="text-sm text-gray-600">
                Le code d'accès permet aux nouveaux utilisateurs de rejoindre votre assemblée. 
                Il est automatiquement renouvelé toutes les 12 heures pour des raisons de sécurité.
              </p>
            </div>
          </div>

          {/* Autres paramètres de sécurité */}
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              Autres paramètres
            </h3>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-gray-900">Renouvellement automatique</p>
                  <p className="text-sm text-gray-600">
                    Le code d'accès est automatiquement renouvelé toutes les 12 heures
                  </p>
                </div>
                <div className="relative inline-flex items-center">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={true}
                    disabled
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-gray-900">Durée de validité</p>
                  <p className="text-sm text-gray-600">
                    Durée de validité du code d'accès
                  </p>
                </div>
                <select
                  className="input w-32"
                  value="12"
                  disabled
                >
                  <option value="12">12 heures</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;
import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { 
  Building2, 
  FileText, 
  BookOpen, 
  Bell, 
  User, 
  Wrench, 
  Settings as SettingsIcon, 
  Flag,
  Shield 
} from 'lucide-react';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const settingsItems = [
    { name: 'Général', href: '/settings', icon: SettingsIcon },
    { name: 'Bannières', href: '/settings/banners', icon: Flag },
    { name: 'Présentoirs', href: '/settings/stands', icon: Building2 },
    { name: 'Affiches', href: '/settings/posters', icon: FileText },
    { name: 'Publications', href: '/settings/publications', icon: BookOpen },
    { name: 'Maintenance', href: '/settings/maintenance', icon: Wrench },
    { name: 'Notifications', href: '/settings/notifications', icon: Bell },
    { name: 'Sécurité', href: '/settings/security', icon: Shield },
    { name: 'Profil', href: '/settings/profile', icon: User }
  ];

  const isActive = (href: string) => {
    if (href === '/settings') {
      return location.pathname === '/settings';
    }
    return location.pathname.startsWith(href);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex gap-8">
        {/* Sidebar */}
        <div className="w-64 flex-shrink-0">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-6">
            Paramètres
          </h1>
          
          <nav className="space-y-1">
            {settingsItems.map((item) => {
              const Icon = item.icon;
              return (
                <button
                  key={item.name}
                  onClick={() => navigate(item.href)}
                  className={`w-full flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-lg transition-colors
                    ${isActive(item.href)
                      ? 'bg-blue-50 text-blue-700 dark:bg-blue-900/50 dark:text-blue-300'
                      : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800'
                    }`}
                >
                  <div className={`p-2 rounded-lg ${
                    isActive(item.href)
                      ? 'bg-blue-100 dark:bg-blue-800'
                      : 'bg-gray-100 dark:bg-gray-700'
                  }`}>
                    <Icon className="h-5 w-5" />
                  </div>
                  <span>{item.name}</span>
                </button>
              );
            })}
          </nav>
        </div>

        {/* Content */}
        <div className="flex-1 min-h-[calc(100vh-8rem)]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Settings;
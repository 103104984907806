import { doc, updateDoc, arrayUnion, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';

export const submitPosterRequest = async (
  standId: string,
  organizationId: string,
  standName: string,
  requestedPoster: string,
  notes: string
) => {
  try {
    const posterRequestData = {
      id: crypto.randomUUID(),
      requestedPoster,
      notes,
      requestDate: new Date().toISOString(),
      status: 'pending',
      requestedBy: 'Visiteur'
    };

    const standRef = doc(db, 'stands', standId);
    
    // Update stand with new poster request
    await updateDoc(standRef, {
      posterRequests: arrayUnion(posterRequestData),
      lastUpdated: serverTimestamp()
    });

    // Create notification
    await addDoc(collection(db, 'organizations', organizationId, 'notifications'), {
      type: 'info',
      title: 'Nouvelle demande de changement d\'affiche',
      message: `Une demande de changement d'affiche a été faite pour le présentoir ${standName}`,
      isRead: false,
      createdAt: serverTimestamp(),
      organizationId,
      category: 'poster_request',
      standId,
      userId: 'admin'
    });

    toast.success('Demande de changement d\'affiche envoyée');
    return true;
  } catch (error) {
    console.error('Error submitting poster request:', error);
    toast.error('Erreur lors de l\'envoi de la demande');
    throw error;
  }
};
import React from 'react';
import { BookOpen, AlertTriangle } from 'lucide-react';
import { Publication } from '../../types';

interface PublicationsListProps {
  publications: Publication[];
  standPublications: Array<{
    publicationId: string;
    quantity: number;
  }>;
}

const PublicationsList: React.FC<PublicationsListProps> = ({ 
  publications,
  standPublications
}) => {
  if (standPublications.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        Aucune publication disponible
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {standPublications.map((pub) => {
        const publication = publications.find(p => p.id === pub.publicationId);
        if (!publication) return null;

        const isLowStock = pub.quantity < publication.minStock;

        return (
          <div 
            key={pub.publicationId}
            className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 hover:shadow-md transition-shadow"
          >
            <div className="flex items-start gap-4">
              <div className="w-16 h-16 rounded-lg overflow-hidden bg-gray-100 flex-shrink-0">
                {publication.imageUrl ? (
                  <img
                    src={publication.imageUrl}
                    alt={publication.title}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <BookOpen className="h-8 w-8 text-gray-400" />
                  </div>
                )}
              </div>

              <div className="flex-1">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="font-medium text-gray-900">{publication.title}</h3>
                    {publication.description && (
                      <p className="text-sm text-gray-600 mt-1">{publication.description}</p>
                    )}
                  </div>

                  {isLowStock && (
                    <span className="flex items-center gap-1 px-2 py-1 bg-orange-100 text-orange-700 rounded-full text-sm">
                      <AlertTriangle className="h-3 w-3" />
                      Stock bas
                    </span>
                  )}
                </div>

                <div className="mt-2 flex items-center gap-2">
                  <span className={`text-sm font-medium ${
                    isLowStock ? 'text-orange-600' : 'text-green-600'
                  }`}>
                    Stock: {pub.quantity}
                  </span>
                  {isLowStock && (
                    <span className="text-sm text-gray-500">
                      (Min. requis: {publication.minStock})
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PublicationsList;
import React from 'react';
import { Building2, Calendar, Wrench, AlertTriangle } from 'lucide-react';

interface StandFiltersProps {
  currentFilter: string;
  onFilterChange: (filter: string) => void;
  counts: {
    all: number;
    available: number;
    reserved: number;
    maintenance: number;
    lowStock: number;
  };
}

const StandFilters: React.FC<StandFiltersProps> = ({
  currentFilter,
  onFilterChange,
  counts
}) => {
  const filters = [
    {
      id: 'all',
      label: 'Tous',
      icon: Building2,
      count: counts.all,
      color: 'blue'
    },
    {
      id: 'available',
      label: 'Disponibles',
      icon: Building2,
      count: counts.available,
      color: 'green'
    },
    {
      id: 'reserved',
      label: 'Réservés',
      icon: Calendar,
      count: counts.reserved,
      color: 'red'
    },
    {
      id: 'maintenance',
      label: 'Maintenance',
      icon: Wrench,
      count: counts.maintenance,
      color: 'yellow'
    },
    {
      id: 'lowStock',
      label: 'Stock bas',
      icon: AlertTriangle,
      count: counts.lowStock,
      color: 'orange'
    }
  ];

  return (
    <div className="flex flex-wrap gap-2 mb-6">
      {filters.map(({ id, label, icon: Icon, count, color }) => {
        const isActive = currentFilter === id;
        const colorClasses = {
          blue: 'bg-blue-100 text-blue-700 hover:bg-blue-200',
          green: 'bg-green-100 text-green-700 hover:bg-green-200',
          red: 'bg-red-100 text-red-700 hover:bg-red-200',
          yellow: 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200',
          orange: 'bg-orange-100 text-orange-700 hover:bg-orange-200'
        }[color];

        return (
          <button
            key={id}
            onClick={() => onFilterChange(id)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all ${
              isActive
                ? `${colorClasses} shadow-md transform -translate-y-0.5`
                : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            <Icon className="h-4 w-4" />
            <span>{label}</span>
            <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
              isActive ? 'bg-white bg-opacity-50' : 'bg-gray-100'
            }`}>
              {count}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default StandFilters;
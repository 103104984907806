import React from 'react';
import { MapPin, Calendar, User } from 'lucide-react';
import { DisplayStand } from '../../types';
import { formatDateSafely } from '../../utils/dateUtils';

interface StandHeaderProps {
  stand: DisplayStand;
}

const StandHeader: React.FC<StandHeaderProps> = ({ stand }) => {
  return (
    <div className="space-y-4">
      <div className="flex items-start justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{stand.name}</h1>
          <div className="flex items-center text-gray-600 mt-2">
            <MapPin className="h-5 w-5 mr-2 text-blue-500" />
            <span>{stand.location}</span>
          </div>
        </div>

        <div className={`px-4 py-2 rounded-lg ${
          stand.isReserved
            ? 'bg-red-100 text-red-700'
            : 'bg-green-100 text-green-700'
        }`}>
          <span className="font-medium">
            {stand.isReserved ? 'Réservé' : 'Disponible'}
          </span>
        </div>
      </div>

      {stand.isReserved && (
        <div className="bg-red-50 rounded-lg p-4 space-y-2">
          <div className="flex items-center text-red-700">
            <User className="h-5 w-5 mr-2" />
            <span className="font-medium">Réservé par: {stand.reservedBy}</span>
          </div>
          <div className="flex items-center text-red-700">
            <Calendar className="h-5 w-5 mr-2" />
            <span>
              {stand.isPerpetual 
                ? 'Réservation perpétuelle'
                : `Jusqu'au ${formatDateSafely(stand.reservedUntil)}`
              }
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default StandHeader;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { DisplayStand, Publication, Poster } from '../../types';
import StandHeader from './StandHeader';
import CurrentPoster from './CurrentPoster';
import PublicationsList from './PublicationsList';
import StandActions from './StandActions';
import LoadingSpinner from './LoadingSpinner';
import NotFoundView from './NotFoundView';
import ReservationModal from '../ReservationModal';
import PosterRequestModal from '../PosterRequestModal';
import MaintenanceRequestModal from '../MaintenanceRequestModal';
import StockAlertModal from './StockAlertModal';
import { submitPosterRequest } from '../../services/posterRequestService';
import { cancelReservation } from '../../services/reservationService';
import { toast } from 'react-hot-toast';

const PublicStandView: React.FC = () => {
  const { id } = useParams();
  const [stand, setStand] = useState<DisplayStand | null>(null);
  const [publications, setPublications] = useState<Publication[]>([]);
  const [availablePosters, setAvailablePosters] = useState<Poster[]>([]);
  const [loading, setLoading] = useState(true);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [showPosterRequestModal, setShowPosterRequestModal] = useState(false);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [showStockAlertModal, setShowStockAlertModal] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (!id) return;

      try {
        // Load stand
        const standDoc = await getDoc(doc(db, 'stands', id));
        if (!standDoc.exists()) {
          setLoading(false);
          return;
        }

        const standData = { id: standDoc.id, ...standDoc.data() } as DisplayStand;
        setStand(standData);

        // Load posters
        const postersQuery = query(
          collection(db, 'posters'),
          where('organizationId', '==', standData.organizationId)
        );
        const postersSnapshot = await getDocs(postersQuery);
        const postersData = postersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Poster[];
        setAvailablePosters(postersData);

        // Load publications
        if (standData.publications?.length > 0) {
          const publicationPromises = standData.publications.map(pub => 
            getDoc(doc(db, 'publications', pub.publicationId))
          );
          const publicationDocs = await Promise.all(publicationPromises);
          const publicationsData = publicationDocs
            .filter(doc => doc.exists())
            .map(doc => ({ id: doc.id, ...doc.data() })) as Publication[];
          setPublications(publicationsData);
        }
      } catch (error) {
        console.error('Error loading data:', error);
        toast.error('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [id]);

  const handlePosterRequest = async (requestedPoster: string, notes: string) => {
    if (!stand) return;

    try {
      await submitPosterRequest(
        stand.id,
        stand.organizationId,
        stand.name,
        requestedPoster,
        notes
      );
      setShowPosterRequestModal(false);
    } catch (error) {
      console.error('Error submitting poster request:', error);
      toast.error('Erreur lors de l\'envoi de la demande');
    }
  };

  const handleCancelReservation = async () => {
    if (!stand) return;

    try {
      const standRef = doc(db, 'stands', stand.id);
      await updateDoc(standRef, {
        isReserved: false,
        reservedBy: null,
        reservedUntil: null,
        isPerpetual: false,
        lastUpdated: serverTimestamp()
      });

      // Create notification
      await addDoc(collection(db, 'organizations', stand.organizationId, 'notifications'), {
        type: 'info',
        title: 'Réservation annulée',
        message: `La réservation du présentoir ${stand.name} a été annulée`,
        isRead: false,
        createdAt: serverTimestamp(),
        organizationId: stand.organizationId,
        category: 'reservation',
        standId: stand.id
      });

      setStand(prev => prev ? {
        ...prev,
        isReserved: false,
        reservedBy: null,
        reservedUntil: null,
        isPerpetual: false
      } : null);

      toast.success('Réservation annulée avec succès');
    } catch (error) {
      console.error('Error canceling reservation:', error);
      toast.error('Erreur lors de l\'annulation de la réservation');
    }
  };

  const handleStockAlert = async (publicationId: string, currentStock: number) => {
    if (!stand) return;

    try {
      // Update stock in stand
      const standRef = doc(db, 'stands', stand.id);
      await updateDoc(standRef, {
        publications: stand.publications?.map(pub => 
          pub.publicationId === publicationId
            ? { ...pub, quantity: currentStock }
            : pub
        ),
        lastUpdated: serverTimestamp()
      });

      // Create notification
      await addDoc(collection(db, 'organizations', stand.organizationId, 'notifications'), {
        type: 'warning',
        title: 'Alerte stock',
        message: `Le stock d'une publication du présentoir ${stand.name} a été mis à jour`,
        isRead: false,
        createdAt: serverTimestamp(),
        organizationId: stand.organizationId,
        category: 'stock',
        standId: stand.id
      });

      setShowStockAlertModal(false);
      toast.success('Stock mis à jour avec succès');
    } catch (error) {
      console.error('Error updating stock:', error);
      toast.error('Erreur lors de la mise à jour du stock');
    }
  };

  if (loading) return <LoadingSpinner />;
  if (!stand) return <NotFoundView />;

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="card p-8 bg-white shadow-xl border-2 border-blue-100">
          <div className="space-y-6">
            <StandHeader stand={stand} />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <CurrentPoster 
                posterName={stand.currentPoster}
                posterImage={availablePosters.find(p => p.name === stand.currentPoster)?.imageUrl}
              />

              <div className="space-y-4">
                <h2 className="text-lg font-semibold text-gray-900">
                  Publications disponibles
                </h2>
                <PublicationsList 
                  publications={publications}
                  standPublications={stand.publications || []}
                />
              </div>
            </div>

            <StandActions
              isReserved={stand.isReserved}
              onReserve={() => setShowReservationModal(true)}
              onPosterRequest={() => setShowPosterRequestModal(true)}
              onMaintenanceRequest={() => setShowMaintenanceModal(true)}
              onStockAlert={() => setShowStockAlertModal(true)}
              onCancelReservation={handleCancelReservation}
            />
          </div>
        </div>
      </div>

      {showReservationModal && (
        <ReservationModal
          stand={stand}
          isOpen={showReservationModal}
          onClose={() => setShowReservationModal(false)}
        />
      )}

      {showPosterRequestModal && (
        <PosterRequestModal
          stand={stand}
          isOpen={showPosterRequestModal}
          onClose={() => setShowPosterRequestModal(false)}
          availablePosters={availablePosters}
          onSubmit={handlePosterRequest}
        />
      )}

      {showMaintenanceModal && (
        <MaintenanceRequestModal
          stand={stand}
          isOpen={showMaintenanceModal}
          onClose={() => setShowMaintenanceModal(false)}
        />
      )}

      {showStockAlertModal && (
        <StockAlertModal
          stand={stand}
          publications={publications}
          isOpen={showStockAlertModal}
          onClose={() => setShowStockAlertModal(false)}
          onSubmit={handleStockAlert}
        />
      )}
    </div>
  );
};

export default PublicStandView;
import React, { useState } from 'react';
import Modal from '../Modal';
import { DisplayStand, Publication } from '../../types';
import { BookOpen, AlertTriangle } from 'lucide-react';

interface StockAlertModalProps {
  stand: DisplayStand;
  publications: Publication[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (publicationId: string, currentStock: number) => void;
}

const StockAlertModal: React.FC<StockAlertModalProps> = ({
  stand,
  publications,
  isOpen,
  onClose,
  onSubmit
}) => {
  const [selectedPublication, setSelectedPublication] = useState('');
  const [currentStock, setCurrentStock] = useState(0);
  const [notes, setNotes] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedPublication) return;
    onSubmit(selectedPublication, currentStock);
  };

  const standPublications = stand.publications || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Signaler un stock bas">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-orange-50 p-4 rounded-lg">
          <div className="flex items-center gap-2 text-orange-700 mb-2">
            <AlertTriangle className="h-5 w-5" />
            <span className="font-medium">Alerte de stock</span>
          </div>
          <p className="text-sm text-orange-600">
            Signalez un niveau de stock bas pour une publication
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Publication concernée
          </label>
          <select
            className="input"
            value={selectedPublication}
            onChange={(e) => setSelectedPublication(e.target.value)}
            required
          >
            <option value="">Sélectionner une publication</option>
            {standPublications.map(pub => {
              const publication = publications.find(p => p.id === pub.publicationId);
              if (!publication) return null;
              return (
                <option key={pub.publicationId} value={pub.publicationId}>
                  {publication.title}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Stock actuel estimé
          </label>
          <input
            type="number"
            min="0"
            className="input"
            value={currentStock}
            onChange={(e) => setCurrentStock(parseInt(e.target.value))}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Notes complémentaires
          </label>
          <textarea
            className="input"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            rows={3}
            placeholder="Informations complémentaires..."
          />
        </div>

        <div className="flex justify-end space-x-3 pt-4">
          <button
            type="button"
            onClick={onClose}
            className="btn btn-secondary"
          >
            Annuler
          </button>
          <button
            type="submit"
            className="btn btn-primary"
          >
            Envoyer l'alerte
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default StockAlertModal;
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

export const generateAccessCode = (): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = 8;
  let code = '';
  
  for (let i = 0; i < length; i++) {
    code += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  
  return code;
};

export const refreshAccessCode = async (organizationId: string): Promise<string> => {
  const newCode = generateAccessCode();
  const expiryDate = new Date(Date.now() + 12 * 60 * 60 * 1000); // 12 hours from now
  
  try {
    const orgRef = doc(db, 'organizations', organizationId);
    await updateDoc(orgRef, {
      accessCode: newCode,
      accessCodeExpiry: expiryDate
    });
    
    return newCode;
  } catch (error) {
    console.error('Error refreshing access code:', error);
    throw error;
  }
};
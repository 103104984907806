import { create } from 'zustand';
import { collection, query, where, onSnapshot, Timestamp, doc, updateDoc, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';

export interface Notification {
  id: string;
  type: 'info' | 'success' | 'warning' | 'error';
  title: string;
  message: string;
  isRead: boolean;
  createdAt: Timestamp;
  category: 'poster_request' | 'maintenance' | 'stock' | 'reservation';
  standId?: string;
  standName?: string;
  organizationId: string;
  userId: string;
}

interface NotificationStore {
  notifications: Notification[];
  unreadCount: number;
  setNotifications: (notifications: Notification[]) => void;
  subscribeToNotifications: (organizationId: string, userId: string) => () => void;
  markAsRead: (organizationId: string, notificationId: string) => Promise<void>;
  markAllAsRead: (organizationId: string, userId: string) => Promise<void>;
}

export const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  
  setNotifications: (notifications) => {
    set({
      notifications,
      unreadCount: notifications.filter(n => !n.isRead).length
    });
  },

  subscribeToNotifications: (organizationId, userId) => {
    try {
      const notificationsRef = collection(db, 'organizations', organizationId, 'notifications');
      const q = query(
        notificationsRef,
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(q, 
        (snapshot) => {
          const notifications = snapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data()
            })) as Notification[];

          set({
            notifications,
            unreadCount: notifications.filter(n => !n.isRead).length
          });
        },
        (error) => {
          console.error('Error in notifications subscription:', error);
          toast.error('Erreur lors de la récupération des notifications');
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error('Error setting up notifications subscription:', error);
      return () => {};
    }
  },

  markAsRead: async (organizationId, notificationId) => {
    try {
      const notificationRef = doc(db, 'organizations', organizationId, 'notifications', notificationId);
      await updateDoc(notificationRef, {
        isRead: true,
        readAt: Timestamp.now()
      });

      const { notifications } = get();
      const updatedNotifications = notifications.map(notification =>
        notification.id === notificationId
          ? { ...notification, isRead: true }
          : notification
      );

      set({
        notifications: updatedNotifications,
        unreadCount: updatedNotifications.filter(n => !n.isRead).length
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
      toast.error('Erreur lors du marquage de la notification');
      throw error;
    }
  },

  markAllAsRead: async (organizationId, userId) => {
    try {
      const batch = db.batch();
      const { notifications } = get();
      
      notifications
        .filter(n => !n.isRead && n.userId === userId)
        .forEach(notification => {
          const notificationRef = doc(db, 'organizations', organizationId, 'notifications', notification.id);
          batch.update(notificationRef, {
            isRead: true,
            readAt: Timestamp.now()
          });
        });

      await batch.commit();

      const updatedNotifications = notifications.map(notification => ({
        ...notification,
        isRead: true
      }));

      set({
        notifications: updatedNotifications,
        unreadCount: 0
      });

      toast.success('Toutes les notifications ont été marquées comme lues');
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      toast.error('Erreur lors de la mise à jour des notifications');
      throw error;
    }
  }
}));
import React from 'react';
import { Image } from 'lucide-react';

interface CurrentPosterProps {
  posterName?: string;
  posterImage?: string;
}

const CurrentPoster: React.FC<CurrentPosterProps> = ({ posterName, posterImage }) => {
  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold text-gray-900">
        Affiche actuelle
      </h2>
      <div className="aspect-square rounded-lg overflow-hidden bg-gray-100 relative group">
        {posterImage ? (
          <img 
            src={posterImage} 
            alt={posterName}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Image className="h-12 w-12 text-gray-400" />
          </div>
        )}
        {posterName && (
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
            <p className="text-white font-medium">{posterName}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentPoster;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Building2, MapPin, Clock, User, FileText, AlertTriangle, BookOpen, Wrench, Calendar, History, Plus } from 'lucide-react';
import { DisplayStand, Poster, Publication } from '../types';
import { formatDateSafely } from '../utils/dateUtils';
import { needsMaintenance } from '../utils/maintenanceUtils';
import { useSettings } from '../context/SettingsContext';
import { useTheme } from '../context/ThemeContext';
import { getStandAge, getAgeStatus } from '../utils/standUtils';
import HelpTooltip from './HelpTooltip';
import StockPredictionTooltip from './StockPredictionTooltip';
import { QRCodeSVG } from 'qrcode.react';
import { useAuth } from '../context/AuthContext';
import { cancelReservation } from '../services/reservationService';

interface StandGalleryViewProps {
  stands: DisplayStand[];
  publications: Publication[];
  availablePosters: Poster[];
  onPosterRequest: (standId: string, requestedPoster: string, notes: string) => void;
  onUpdateStock: (standId: string, publicationId: string, quantity: number) => void;
  getLowStockPublications: (standId: string) => any[];
  onSelectAction: (stand: DisplayStand, action: string) => void;
}

const StandGalleryView: React.FC<StandGalleryViewProps> = ({
  stands,
  publications,
  availablePosters,
  onPosterRequest,
  onUpdateStock,
  getLowStockPublications,
  onSelectAction
}) => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const { userPreferences } = useTheme();
  const { currentUser } = useAuth();
  const [hoveredPublication, setHoveredPublication] = useState<{
    standId: string;
    publicationId: string;
  } | null>(null);

  const handleQRCodeClick = (standId: string) => {
    window.open(`/stand/${standId}`, '_blank');
  };

  const getStatusColor = (stand: DisplayStand) => {
    if (stand.isReserved) return 'border-red-200 dark:border-red-800';
    const maintenanceStatus = needsMaintenance(stand, settings.maintenance.preventiveIntervalMonths);
    if (maintenanceStatus.needed) {
      return maintenanceStatus.reason === 'curative' 
        ? 'border-red-200 dark:border-red-800' 
        : 'border-yellow-200 dark:border-yellow-800';
    }
    const ageStatus = getAgeStatus(stand.createdAt);
    switch (ageStatus.status) {
      case 'new': return 'border-green-200 dark:border-green-800';
      case 'good': return 'border-blue-200 dark:border-blue-800';
      case 'aging': return 'border-yellow-200 dark:border-yellow-800';
      case 'old': return 'border-red-200 dark:border-red-800';
      default: return 'border-gray-200 dark:border-gray-700';
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {stands.map((stand) => (
        <div 
          key={stand.id} 
          className={`card p-6 hover:shadow-lg transition-all duration-200 border-2 ${getStatusColor(stand)}`}
        >
          <div className="space-y-4">
            <div className="flex items-start gap-4">
              <div className="flex gap-4">
                <div className="w-24 h-24 rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700 flex-shrink-0">
                  {stand.currentPoster && availablePosters.find(p => p.name === stand.currentPoster)?.imageUrl ? (
                    <img
                      src={availablePosters.find(p => p.name === stand.currentPoster)?.imageUrl}
                      alt={stand.currentPoster}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <Building2 className="h-12 w-12 text-gray-400 dark:text-gray-500" />
                    </div>
                  )}
                </div>
                <div 
                  className="w-24 h-24 p-2 bg-white dark:bg-gray-800 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow"
                  onClick={() => handleQRCodeClick(stand.id)}
                  title="Ouvrir la page publique"
                >
                  <QRCodeSVG
                    value={`${window.location.origin}/stand/${stand.id}`}
                    size={80}
                    level="H"
                    includeMargin={true}
                  />
                </div>
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{stand.name}</h3>
                <div className="flex items-center text-gray-600 dark:text-gray-400 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  {stand.location}
                </div>
                {stand.isReserved && (
                  <div className="flex flex-col gap-1 mt-2">
                    <div className="flex items-center text-gray-600 dark:text-gray-400">
                      <User className="h-4 w-4 mr-1" />
                      {stand.reservedBy}
                    </div>
                    <div className="flex items-center text-gray-600 dark:text-gray-400">
                      <Clock className="h-4 w-4 mr-1" />
                      {stand.isPerpetual ? 'Réservation perpétuelle' : `Jusqu'au ${formatDateSafely(stand.reservedUntil)}`}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {stand.publications && stand.publications.length > 0 && (
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Publications</h4>
                {stand.publications.map(pub => {
                  const publication = publications.find(p => p.id === pub.publicationId);
                  if (!publication) return null;
                  
                  return (
                    <div 
                      key={pub.publicationId} 
                      className="relative flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
                      onMouseEnter={() => setHoveredPublication({ standId: stand.id, publicationId: pub.publicationId })}
                      onMouseLeave={() => setHoveredPublication(null)}
                    >
                      <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          {publication.title}
                        </span>
                        <HelpTooltip
                          title="Stock"
                          content={
                            <div className="space-y-2">
                              <p>Niveau de stock actuel par rapport au minimum requis.</p>
                              <p>• Stock minimum : {publication.minStock}</p>
                              <p>• Stock actuel : {pub.quantity}</p>
                            </div>
                          }
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <span className={`text-sm font-medium ${
                          pub.quantity < publication.minStock
                            ? 'text-orange-600 dark:text-orange-400'
                            : 'text-gray-900 dark:text-gray-100'
                        }`}>
                          {pub.quantity} / {publication.minStock}
                        </span>
                        {pub.quantity < publication.minStock && (
                          <AlertTriangle className="h-4 w-4 text-orange-500" />
                        )}
                      </div>
                      
                      {hoveredPublication?.standId === stand.id && 
                       hoveredPublication?.publicationId === pub.publicationId && (
                        <StockPredictionTooltip
                          publication={{
                            ...publication,
                            currentStock: pub.quantity
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            <div className="grid grid-cols-2 gap-2">
              {stand.isReserved ? (
                <button
                  onClick={() => onSelectAction(stand, 'cancel')}
                  className="btn bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/50"
                >
                  Annuler
                </button>
              ) : (
                <button
                  onClick={() => onSelectAction(stand, 'reserve')}
                  className="btn bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-400 hover:bg-green-200 dark:hover:bg-green-900/50"
                >
                  Réserver
                </button>
              )}
              
              <button
                onClick={() => onSelectAction(stand, 'poster')}
                className="btn btn-secondary"
              >
                <FileText className="h-4 w-4 mr-1" />
                Affiche
              </button>
              
              <button
                onClick={() => onSelectAction(stand, 'publications')}
                className="btn btn-secondary"
              >
                <Plus className="h-4 w-4 mr-1" />
                Publications
              </button>
              
              <button
                onClick={() => onSelectAction(stand, 'maintenance')}
                className="btn btn-secondary"
              >
                <Wrench className="h-4 w-4 mr-1" />
                Entretien
              </button>
              
              <button
                onClick={() => onSelectAction(stand, 'history')}
                className="btn btn-secondary col-span-2"
              >
                <History className="h-4 w-4 mr-1" />
                Historique
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StandGalleryView;
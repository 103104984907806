import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Building2, Key, ArrowRight } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { joinOrganization } from '../../lib/organization';
import { toast } from 'react-hot-toast';

const JoinAssemblyFlow: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [accessCode, setAccessCode] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    try {
      setLoading(true);
      await joinOrganization(accessCode, currentUser.uid);
      toast.success('Vous avez rejoint l\'assemblée avec succès');
      navigate('/');
    } catch (error) {
      console.error('Error joining assembly:', error);
      toast.error('Code d\'accès invalide ou expiré');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="max-w-md w-full">
        <div className="card p-8 shadow-xl border-2 border-blue-100">
          <div className="text-center mb-8">
            <div className="flex justify-center mb-4">
              <div className="p-3 bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-xl shadow-lg">
                <Building2 className="h-8 w-8" />
              </div>
            </div>
            <h1 className="text-2xl font-bold text-gray-900">
              Rejoindre une assemblée
            </h1>
            <p className="text-gray-600 mt-2">
              Entrez le code d'accès fourni par votre assemblée
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Code d'accès
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Key className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  className="input pl-10"
                  value={accessCode}
                  onChange={(e) => setAccessCode(e.target.value.toUpperCase())}
                  placeholder="Entrez le code d'accès"
                  required
                  pattern="[A-Z0-9]{8}"
                  maxLength={8}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Le code d'accès est composé de 8 caractères alphanumériques
              </p>
            </div>

            <div className="flex justify-between items-center pt-4">
              <button
                type="button"
                onClick={() => navigate('/onboarding/create')}
                className="text-blue-600 hover:text-blue-700 text-sm font-medium"
              >
                Créer une nouvelle assemblée
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                    Vérification...
                  </div>
                ) : (
                  <>
                    Rejoindre
                    <ArrowRight className="h-4 w-4 ml-2" />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinAssemblyFlow;
import React, { useState, useEffect } from 'react';
import { Bell } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useOrganization } from '../context/OrganizationContext';
import { useNotificationStore } from '../stores/notificationStore';
import NotificationList from './notifications/NotificationList';

const NotificationsToggle: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser } = useAuth();
  const { currentOrganization } = useOrganization();
  const { 
    notifications, 
    unreadCount, 
    subscribeToNotifications, 
    markAsRead, 
    markAllAsRead 
  } = useNotificationStore();

  useEffect(() => {
    if (!currentUser?.uid || !currentOrganization?.id) return;

    const unsubscribe = subscribeToNotifications(
      currentOrganization.id,
      currentUser.uid
    );

    return () => unsubscribe();
  }, [currentUser?.uid, currentOrganization?.id]);

  const handleMarkAsRead = async (notificationId: string) => {
    if (!currentOrganization?.id) return;
    await markAsRead(currentOrganization.id, notificationId);
  };

  const handleMarkAllAsRead = async () => {
    if (!currentOrganization?.id || !currentUser?.uid) return;
    await markAllAsRead(currentOrganization.id, currentUser.uid);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
      >
        <Bell className={`h-5 w-5 ${unreadCount > 0 ? 'animate-[wiggle_1s_ease-in-out_infinite]' : ''}`} />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full animate-pulse">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-96 bg-white dark:bg-gray-800 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50">
          <div className="p-4 border-b border-gray-100 dark:border-gray-700">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                Notifications
              </h3>
              {unreadCount > 0 && (
                <button
                  onClick={handleMarkAllAsRead}
                  className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  Tout marquer comme lu
                </button>
              )}
            </div>
          </div>
          <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
            <NotificationList
              notifications={notifications}
              onMarkAsRead={handleMarkAsRead}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsToggle;